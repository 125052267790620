import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import LoginWrapper  from "./login/LoginWrapper";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import HomePage from "./home/page";
import VirtualMachine from "./virtualmachine/VirtualMachine";
import DashBoard from "./dashboard/DashBoard";
import Purchase from "./purchase/Purchase";
import User from "./user/User";
import {Register} from "./register/RegisterPage";
import {ActiveAccount} from "./register/ActiveAccount";

class App extends React.Component{
    render() {
        return (
            <Router>
                <Routes>
                    <Route path="/" element={<LoginWrapper />} />
                    <Route path="/manage" element={<HomePage />}/>
                    <Route path="/virtual-machine" element={<VirtualMachine />}/>
                    <Route path="/dashboard" element={<DashBoard />}/>
                    <Route path="/purchase" element={<Purchase />}/>
                    <Route path="/user" element={<User />}/>
                    <Route path="/register" element={<Register />}/>
                    <Route path="/active-account" element={<ActiveAccount />}/>
                </Routes>
            </Router>
        );
    }
}

export default App;
