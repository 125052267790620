import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';

function CardPrice({cardTitle
                       , cardContent
                       , buttonContent
                       , cardImage
                       , heightImage}) {
    return (
        <Card style={{ width: '25rem'}}>
            <Card.Img style={{ height: heightImage === null ? '14rem': heightImage}} variant="top" src={cardImage} />
            <Card.Body>
                <Card.Title style={{ fontSize: '1rem', fontWeight: 'bolder' }}>{cardTitle}</Card.Title>
                <Card.Text style={{ fontSize: '0.8rem', fontWeight: 'lighter' }}>
                    {cardContent}
                </Card.Text>
                <Button variant="primary">{buttonContent}</Button>
            </Card.Body>
        </Card>
    );
}

export default CardPrice;