import React, {useEffect, useRef, useState} from 'react';
import {Col, Form, Modal, Row} from "react-bootstrap";
import {Button} from "react-bootstrap";
import {refreshToken} from "../util/refreshToken";
import Cookies from "js-cookie";
import {ShowModalAuth} from "./fail401Login";
import {Popup} from "./popup";
import Constants from "../home/Constants";

export const ShowModalCreateVm = ({showModalCreateVm, onHide, setShowModalCreateVm}) => {
    const [data, setData] = useState([]);
    const [packages, setPackages] = useState([]);
    const [noGpu, setNoGpu] = useState([]);
    const [allPackage, setAllPackage] = useState([]);
    const vmRef = useRef("");
    const usernameRef = useRef("");
    const passwordRef = useRef("");
    const [errorCode, setErrorCode] = useState(200);
    const [errorString, setErrorString] = useState("");
    const [showModalFail, setModalFail] = useState(false);
    const [titlePopup, settTitlePopup] = useState("");
    const [titleContent, setTitleContent] = useState('');
    const [showPopup, setShowPopup] = useState(false);
    const configRef = useRef(0);
    const osRef = useRef("");
    const [price, setPrice] = useState(0);
    useEffect(() => {
        setPackages([]);
        setAllPackage([]);
        setNoGpu([]);
        if(showModalCreateVm){
            const findAllOsOptions = {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + Cookies.get('accessToken')},
            };
            fetch(Constants.SERVER + "/vm/microservices/v1/account-service/os-stock/find-all", findAllOsOptions).then(response => {
                const statusCode = response.status;
                if (statusCode === 200) {
                    return response.json();
                }else if(statusCode === 401){
                    refreshToken();
                } else {
                    response.text().then(body => {
                        JSON.parse(body);
                    });
                }
            }).then(dataResult => {
                // dataResult && dataResult[0].isGPU === "ACTIVE" ? setIsGpuActive(true) : setIsGpuActive(false);
                setData(dataResult);
            }).catch(error => {
                console.error('Có lỗi xảy ra:', error);
            });


            const findAllPackageOptions = {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + Cookies.get('accessToken')},
            };
            fetch(Constants.SERVER + "/vm/microservices/v1/account-service/os-stock/find-all-package", findAllPackageOptions).then(response => {
                const statusCode = response.status;
                if (statusCode === 200) {
                    return response.json();
                }else if(statusCode === 401){
                    refreshToken();
                } else {
                    response.text().then(body => {
                        let bodyFail = JSON.parse(body);
                        setErrorString(bodyFail['message']);
                        setModalFail(true);
                        setErrorCode(response.status)
                    });
                }
            }).then(dataResult => {
                setPackages([]);
                setAllPackage([]);
                setNoGpu([]);
                if (dataResult) {
                    setPackages(dataResult);
                    setAllPackage(dataResult);
                    const filteredNoGpu = dataResult.filter((its) => its.isGpu !== "ACTIVE");
                    setNoGpu(filteredNoGpu);
                    setPrice(dataResult[0].price);
                }
            }).catch(error => {
                console.error('Có lỗi xảy ra:', error);
            });

        }
    }, [showModalCreateVm]);
    const handleShowGPU = (event) =>  {
        const stateGPU = event.target.value.split(":")[1];
        if(stateGPU !== "ACTIVE"){
            setPackages(noGpu);
        }else{
            setPackages(allPackage);
        }
        if(packages.length > 0){
            const matchedItem = packages[0];
            configRef.current.value = matchedItem.id;
            setPrice(matchedItem.price);
        }
    }
    const handleClickConfig = (event) => {
        const idPackage = Number.parseInt(event.target.value);
        const matchedItem = packages.find((item) => idPackage === item.id);
        if (matchedItem) {
            setPrice(matchedItem.price);
        }
    }
    const verifyPassword = (password) => {
        const minLength = 8;
        // Kiểm tra mật khẩu không có khoảng trắng
        const hasWhiteSpace = /\s/.test(password);
        // Kiểm tra mật khẩu tối thiểu 8 ký tự
        if (password.length < minLength) {
            setErrorString("Mật khẩu có ít nhất 8 kí tự");
            setErrorCode(403);
            setModalFail(true);
            return false;
        }
        else if (hasWhiteSpace) {
            setErrorString("Mật khẩu không được chứa khoảng trắng.");
            setErrorCode(403);
            setModalFail(true);
            return false;
        }
        else {
            return true;
        }
    };
    const verifyUsername = (username) => {
        const hasWhiteSpace = /\s/.test(username);
        const hasSpecialChar = /[^a-zA-Z0-9]/.test(username);
        // Kiểm tra nếu tên người dùng có khoảng trắng
        if (hasWhiteSpace) {
            setErrorString('Tên người dùng không được chứa khoảng trắng.');
            setErrorCode(403);
            return false;
        }
        // Kiểm tra nếu tên người dùng có ký tự đặc biệt
        else if (hasSpecialChar) {
            setErrorString('Tên người dùng không được chứa ký tự đặc biệt.');
            setErrorCode(403);
            return false;
        }
        else {
            return true;
        }
    };
    const handleCreateVn = () =>{
        const loginOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + Cookies.get('accessToken')},
            body: JSON.stringify({
                typeVm: osRef.current.value.split(":")[0],
                name: vmRef.current.value,
                username: usernameRef.current.value,
                password: passwordRef.current.value,
                packageId: configRef.current.value,
            })
        };
        if(!verifyPassword(passwordRef.current.value)){
            setModalFail(true);
            return;
        }
        if(!verifyUsername(usernameRef.current.value)){
            setModalFail(true);
            return;
        }
        fetch(Constants.SERVER + "/vm/microservices/v1/account-service/vm/create", loginOptions).then(response => {
            const statusCode = response.status;
            if (statusCode === 200) {
                return response.json();
            }else if(statusCode === 401){
                refreshToken();
                setErrorString("Có lỗi xảy ra. Thử lại!");
                setModalFail(true);
                setErrorCode(response.status);
            } else {
                response.text().then(body => {
                    let bodyFail = JSON.parse(body);
                    setErrorString(bodyFail['message']);
                    setModalFail(true);
                    setErrorCode(response.status);
                });
                return null;
            }
        }).then(dataResult => {
            if(dataResult !== null){
                setShowPopup(true);
                setShowModalCreateVm(false);
                settTitlePopup("Thành công");
                setTitleContent("Khởi tạo Virtual Machine thành công!");
            }
        }).catch(error => {
            console.error('Có lỗi xảy ra:', error);
        });
    }
    return (
        <div>
            <Modal className={"modal-lg"} show={showModalCreateVm} onHide={onHide} backdrop="static" keyboard={false}>
                <Modal.Header closeButton>
                    <Modal.Title>Thêm Virtual Machine</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form style={{fontSize:"13px"}}>
                        <Form.Group as={Row} className="mb-3" controlId="formHorizontal">
                            <Form.Label column sm="2" style={{ fontSize: "13px" }}>Tên VM</Form.Label>
                            <Col sm="10">
                                <Form.Control ref={vmRef} style={{ fontSize: "13px" }} placeholder="Nhập tên vm" />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3" controlId="formHorizontal">
                            <Form.Label column sm="2" style={{ fontSize: "13px" }}>Vm Username</Form.Label>
                            <Col sm="10">
                                <Form.Control ref={usernameRef} style={{ fontSize: "13px" }} placeholder="Nhập username đăng nhập máy chủ" />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3" controlId="formHorizontal">
                            <Form.Label column sm="2" style={{ fontSize: "13px" }}>Vm Password</Form.Label>
                            <Col sm="10">
                                <Form.Control ref={passwordRef} style={{ fontSize: "13px" }} placeholder="Nhập password máy chủ" />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3">
                            <Form.Label column sm="2" style={{ fontSize: "13px" }}>Hệ điều hành</Form.Label>
                            <Col sm="10">
                                <Form.Select ref={osRef} style={{fontSize: "13px"}} aria-label="Select items per page" onChange={handleShowGPU}>
                                    {data && data.map( (its) => (
                                        <option key={its.osCode+":"+its.isGPU} value={its.osCode+":"+its.isGPU}>{its.detail}</option>
                                    ))}
                                </Form.Select>
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3">
                            <Form.Label column sm="2" style={{ fontSize: "13px" }}>Cấu hình</Form.Label>
                            <Col sm="10">
                                <Form.Select ref={configRef} id="configChecker" style={{fontSize: "13px"}} aria-label="Select items per page" onClick={handleClickConfig}>
                                    {packages && packages.map( (item) => (
                                        <option key={item.id} value={item.id}>{"VCPU: " + item.vcpu
                                        + " - RAM: " + item.ram + " GB"
                                        + " - Disk Size: " + item.diskSize + " GB"
                                        + " - Architecture: " + item.architecture
                                        + (item.isGpu === "ACTIVE" ? " - Có" : " - Không" )+ " GPU"}</option>
                                    ))}
                                </Form.Select>
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3" controlId="formHorizontal">
                            <Form.Label column sm="2" style={{ fontSize: "13px" }}>Giá tiền</Form.Label>
                            <Col sm="10">
                                <Form.Control value={price + " VND"} style={{ fontSize: "13px" }} readOnly />
                            </Col>
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleCreateVn}>Tạo VM</Button>
                    <Button variant="secondary" onClick={onHide}>Close</Button>
                </Modal.Footer>
                <ShowModalAuth errorCode={errorCode} errorStr={errorString} showModal={showModalFail} onHide={() => setModalFail(false)}/>
            </Modal>
            <Popup title={titlePopup} content={titleContent} showPopup={showPopup} hidePopup={() => setShowPopup(false)}/>
        </div>

    );
}