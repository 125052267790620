import {useNavigate} from "react-router-dom";
import NavBarPage from "../navbar/NavBarPage";

import React from "react";

const DashBoard = () => {
    const navigate = useNavigate();
    return (
        <div>
            <NavBarPage currentTab={2} navigate={navigate} />
        </div>
    );
}
export default DashBoard;