import Container from "react-bootstrap/Container";
import React, {useEffect, useState} from "react";
import {ShowModalAuth} from "../popup/fail401Login";
import {Popup} from "../popup/popup";
import {useLocation} from "react-router-dom";
import {refreshToken} from "../util/refreshToken";
import {CardBody, Form} from "react-bootstrap";
import Card from "react-bootstrap/Card";
import {faCircleCheck, faXmarkCircle} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Constants from "../home/Constants";

export const ActiveAccount = () => {
    const location = useLocation();
    const [success, setSuccess] = useState(true);
    useEffect(() => {
        const urlParams = new URLSearchParams(location.search);
        const token = urlParams.get('token');
        if(token === null || token === ""){
            setSuccess(false);
        }else {
            const activeAccount = {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                },
            };
            fetch(Constants.SERVER + "/vm/microservices/v1/account-service/account/active?token=" + token, activeAccount).then(response => {
                const statusCode = response.status;
                if (statusCode === 200) {
                    return response.json();
                }else if(statusCode === 401){
                    setSuccess(false);
                } else {
                    response.text().then(body => {
                        setSuccess(false);
                    });
                }
                return null;
            }).then(data => {
                if(data != null){
                    setSuccess(true);
                }else{
                    setSuccess(false);
                }
            }).catch(error => {
                console.error('Có lỗi xảy ra:', error);
            });
        }
    }, [location]);
    return (
        <div>
            <Container className="py-5 h-100">
                <div className="row d-flex justify-content-center align-items-center h-100">
                    <div className="row d-flex justify-content-center align-items-center h-100">
                        <div className="col-12 col-md-8 col-lg-6 col-xl-5">
                            <Card className="text-white" style={{borderRadius: '1rem', backgroundColor: "#0078d4"}}>
                                {success ? (
                                    <>
                                        <CardBody className="p-5">
                                            <h2 className="text-center fw-bold">Bạn đã kích hoạt tài khoản thành công</h2>
                                        </CardBody>
                                        <FontAwesomeIcon className="text-center fw-bold mb-5" style={{fontSize: "8rem"}} icon={faCircleCheck}/>
                                        <div className="text-center mb-5">
                                            <a href="/" className="text-white fw-bold">Về trang đăng nhập?</a>
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <CardBody className="p-5">
                                            <h2 className="text-center fw-bold">Kích hoạt tài khoản không thành công! Liên hệ hỗ trợ xxxx</h2>
                                        </CardBody>
                                        <FontAwesomeIcon className="text-center fw-bold mb-5" style={{fontSize: "8rem"}} icon={faXmarkCircle}/>
                                        <div className="text-center mb-5">
                                            <a href="/" className="text-white fw-bold">Về trang đăng nhập?</a>
                                        </div>
                                    </>
                                )}
                            </Card>

                        </div>
                    </div>
                </div>
            </Container>
        </div>
    );
}