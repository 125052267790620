import React from "react";
import './navbarpage.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { fa0, faHome, faDesktop, faChartLine, faCartShopping, faUser } from '@fortawesome/free-solid-svg-icons'
import {Link} from "react-router-dom";
import PropTypes from "prop-types";


export default class NavBarPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            activeTab: 0,
            currentTab: props.currentTab || 0
        };
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
    }

    handleResize = () => {
        setTimeout(() => { this.updateSelectorPosition(); }, 100);
    };
    componentDidMount() {
        this.updateSelectorPosition();
        window.addEventListener('resize', this.handleResize);
        this.setActiveTabByPath();
    }
    updateSelectorPosition = () => {
        const activeItem = document.querySelector('#navbarSupportedContent .active .nav-link');
        // console.log('offsetTop: ' + activeItem.offsetTop
        //     + '=offsetLeft: ' + activeItem.offsetLeft
        //     +'=offsetHeight: '+ activeItem.offsetHeight
        //     + '=offsetWidth: '  + activeItem.offsetWidth
        //     + '=getBoundingClientRect: '  + activeItem.getBoundingClientRect());

        const horiSelector = document.querySelector('.hori-selector');
        if (activeItem && horiSelector) {
            const { offsetTop, offsetLeft, offsetHeight, offsetWidth } = activeItem;
            horiSelector.style.top = offsetTop + "px";
            horiSelector.style.left = offsetLeft + "px";
            horiSelector.style.height = offsetHeight + "px";
            horiSelector.style.width = offsetWidth + "px";
        }
    };

    setActiveTabByPath = () => {
        const path = window.location.pathname;
        // console.log(path);
        const index = path === '/home' ? 0 :
            path === '/address-book' ? 1 :
                path === '/components' ? 2 :
                    path === '/calendar' ? 3 :
                        path === '/documents' ? 4 : 0;
        this.setState({ activeTab: index });
    }

    handleTabClick = (index) => {
        this.setState({ activeTab: index }, () => {
            this.updateSelectorPosition();
        });
    };

    toggleNavbar = () => {
        const navbarCollapse = document.querySelector('.navbar-collapse');
        navbarCollapse.classList.toggle('show');
        setTimeout(() => { this.updateSelectorPosition(); }, 300);
    };


    render() {

        const routes = ['/manage', '/virtual-machine', '/dashboard', '/purchase', '/user'];
        return (
            <nav className="navbar navbar-expand-custom navbar-mainbg">
                <a className="navbar-brand navbar-logo">Logo</a>
                <button className="navbar-toggler" type="button"
                        aria-controls="navbarSupportedContent"
                        aria-expanded="false"
                        aria-label="Toggle navigation"
                        onClick={this.toggleNavbar}>
                    <i className="fas fa-bars text-white"/>
                </button>
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav ms-auto">
                        <div className="hori-selector">
                            <div className="left"/>
                            <div className="right"/>
                        </div>
                        <li key={0} className={"nav-item " + ((this.state.currentTab === 0) ? "active": "")}>
                            <Link to={routes[0]} className="nav-link">
                                <FontAwesomeIcon style={{ marginRight: '8px' }} icon={faHome}/>Home
                            </Link>
                        </li>
                        <li key={1} className={"nav-item " + ((this.state.currentTab === 1)? "active": "")}>
                            <Link to={routes[1]} className="nav-link">
                                <FontAwesomeIcon style={{ marginRight: '8px' }} icon={faDesktop}/>Virtual machine
                            </Link>
                        </li>
                        <li key={2} className={"nav-item " + ((this.state.currentTab === 2)? "active": "")}>
                            <Link to={routes[2]} className="nav-link">
                                <FontAwesomeIcon style={{ marginRight: '8px' }} icon={faChartLine}/>Thống kê
                            </Link>
                        </li>
                        <li key={3} className={"nav-item " + ((this.state.currentTab === 3)? "active": "")}>
                            <Link to={routes[3]} className="nav-link">
                                <FontAwesomeIcon style={{ marginRight: '8px' }} icon={faCartShopping}/>Nạp tiền
                            </Link>
                        </li>
                        <li key={4} className={"nav-item " + ((this.state.currentTab === 4)? "active": "")}>
                            <Link to={routes[4]} className="nav-link">
                                <FontAwesomeIcon style={{ marginRight: '8px' }} icon={faUser}/>Tài khoản
                            </Link>
                        </li>
                    </ul>
                </div>
            </nav>
        );
    }
}