import {useNavigate} from "react-router-dom";
import NavBarPage from "../navbar/NavBarPage";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, {useEffect, useState} from "react";
import Container from "react-bootstrap/Container";
import {Pagination} from "react-bootstrap";
import {Button, Col, Form, InputGroup, ProgressBar, Row, Table} from "react-bootstrap";
import {faSearch} from '@fortawesome/free-solid-svg-icons'
import Cookies from "js-cookie";
import {refreshToken} from "../util/refreshToken";
import Constants from "../home/Constants";

const VirtualMachine = () => {
    const [token, setToken] = useState(Cookies.get('accessToken') || null);
    const [itemsPerPage, setItemsPerPage] = useState(5);
    const [currentPage, setCurrentPage] = useState(1);
    const [data, setData] = useState([]);
    const [totalPages, setTotalPages] = useState(0);
    const [searchQuery, setSearchQuery] = useState('');
    const navigate = useNavigate();

    function search(keyword, page){
        const loginOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token},
        };
        fetch(Constants.SERVER + "/vm/microservices/v1/account-service/vm/find-by-keyword?keyword="+keyword+"&limit="+itemsPerPage+"&page=" + page, loginOptions).then(response => {
            const statusCode = response.status;
            if (statusCode === 200) {
                return response.json();
            }else if(statusCode === 401){
                refreshToken();
            } else {
                response.text().then(body => {
                    let bodyFail = JSON.parse(body);
                });
            }
        }).then(data => {
            console.log(data);
            setData(data.data);
            setTotalPages(data.totalPages);
        }).catch(error => {
            console.error('Có lỗi xảy ra:', error);
        });
    }

    function getData(page){
        const loginOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token},
        };
        fetch(Constants.SERVER + "/vm/microservices/v1/account-service/vm/find-by-username?limit="+itemsPerPage+"&page=" + page, loginOptions).then(response => {
            const statusCode = response.status;
            if (statusCode === 200) {
                return response.json();
            }else if(statusCode === 401){
                refreshToken();
            } else {
                response.text().then(body => {
                    let bodyFail = JSON.parse(body);
                });
            }
        }).then(data => {
            console.log(data);
            setData(data.data);
            setTotalPages(data.totalPages);
        }).catch(error => {
            console.error('Có lỗi xảy ra:', error);
        });
    }
    const getProgressVariant = (value) => {
        return value > 80 ? "danger" : "primary";
    };
    const handlePageChange = (pageNumber) => {
        getData(pageNumber-1);
        setCurrentPage(pageNumber);
    }
    const paginationItems = [];
    for (let i = 1; i <= totalPages; i++) {
        console.log("i == " + i + " currentPage=" + currentPage);
        paginationItems.push(
            <Pagination.Item  key={i} active={i === currentPage} onClick={() => handlePageChange(i)}>
                {i}
            </Pagination.Item>
        );
    }
    const handleSearch = (event) => {
        const keyword = event.target.value;
        search(keyword, 0);
    }
    const handleItemsPerPageChange = (event) => {
        setItemsPerPage(Number(event.target.value));
        setCurrentPage(1);
    };
    useEffect(() => {
        getData(0);
    }, [itemsPerPage]);
    return (
        <div>
            <NavBarPage currentTab={1} navigate={navigate} />
            <Container className="mt-5">
                <span >Virtual Machine</span>
                <Row className="justify-content-left mt-3">
                    <Col md={4} lg={4}>
                        <InputGroup>
                            <Form.Control type="search" placeholder="Search" aria-label="Search" value={searchQuery}/>
                            <Button variant="primary" >
                                <FontAwesomeIcon style={{ marginRight: '8px' }} icon={faSearch}/>
                            </Button>
                        </InputGroup>
                    </Col>
                </Row>
            </Container>
            <Container className="mt-5">
                <Table style={{fontSize:"11px", textAlign:"center"}}>
                    <thead>
                        <tr>
                            <th>STT</th>
                            <th>Name</th>
                            <th>State</th>
                            <th>Virtual processors</th>
                            <th>Host server</th>
                            <th>CPU usage</th>
                            <th>Assigned memory</th>
                            <th>Memory pressure</th>
                            <th>Memory demand</th>
                            <th>Number of Disk</th>
                            <th>Uptime</th>
                            <th>Date Created</th>
                            <th>Date Updated</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.map((item, index) => (
                            <tr key={item.id}>
                                <td>{index + 1}</td>
                                <td>{item.name}</td>
                                <td>{item.state}</td>
                                <td>{item.virtualProcessors}</td>
                                <td>{item.hostServer}</td>
                                <td>{item.cpuUsage}</td>
                                <td>{item.assignedMemory}</td>
                                <td>
                                    <ProgressBar
                                        now={item.memoryPressure}
                                        label={`${item.memoryPressure}%`}
                                        variant={getProgressVariant(item.memoryPressure)}
                                    />
                                </td>
                                <td>{item.memoryDemand}</td>
                                <td>{item.numberOfDisk}</td>
                                <td>{item.uptime}</td>
                                <td>{item.dateCreated}</td>
                                <td>{item.dateUpdated}</td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
                <Row className="justify-content-between align-items-center">
                    <Col md="auto">
                        <Form.Select style={{fontSize: "13px"}} value={itemsPerPage} onChange={handleItemsPerPageChange} aria-label="Select items per page">
                            <option value={5}>5 bản ghi mỗi trang</option>
                            <option value={10}>10 bản ghi mỗi trang</option>
                            <option value={15}>15 bản ghi mỗi trang</option>
                            <option value={20}>20 bản ghi mỗi trang</option>
                        </Form.Select>
                    </Col>
                    <Col md="auto">
                        <Pagination>{paginationItems}</Pagination>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}
export default VirtualMachine;