import React, {useState} from "react";
import {useNavigate} from "react-router-dom";
import NavBarPage from "../navbar/NavBarPage";
import CardPrice from "./CardPrice";
import Container from "react-bootstrap/Container";
import card1 from '../asset/1.png';
import card2 from '../asset/2.png';
import card3 from '../asset/3.png';
import vm from '../asset/vm.png';
import add from '../asset/add.png';
import Card from "react-bootstrap/Card";
import {ShowModalAuth} from "../popup/fail401Login";
import {ShowModalCreateVm} from "../popup/addVM";


const HomePage = () => {
    const navigate = useNavigate();
    const [createVm, setCreateVm] = useState(false);
    const [errorCode, setErrorCode] = useState(200);
    const [errorString, setErrorString] = useState(200);
    const [showModal, setShowModal] = useState(false);
    function handleOnOnclickVM(){
        navigate('/virtual-machine');
    }
    function handleOnOnclickAddVM(){
        setCreateVm(true);
    }
    return (
        <div >
            <NavBarPage currentTab={0} navigate={navigate} />
            {/*<Container className={'d-flex justify-content-between mt-5'} style={{display: "none"}}>*/}
            {/*    <div>*/}
            {/*        <CardPrice cardTitle={"Start with an Azure free trial"}*/}
            {/*                   cardContent={"Get $200 free credit toward Azure products and services, plus 12 months of popular free services."}*/}
            {/*                   cardImage={card1}*/}
            {/*                   buttonContent={"Start"}/>*/}
            {/*    </div>*/}
            {/*    <div>*/}
            {/*        <CardPrice cardTitle={"Manage Microsoft Entra ID"}*/}
            {/*                   cardContent={"Manage access, set smart policies, and enhance security with Microsoft Entra ID."}*/}
            {/*                   cardImage={card2}*/}
            {/*                   buttonContent={"View"}/>*/}
            {/*    </div>*/}
            {/*    <div>*/}
            {/*        <CardPrice cardTitle={"Access student benefits"}*/}
            {/*                   cardContent={"Get free software, Azure credit, or access Azure Dev Tools for Teaching after you verify your academic status."}*/}
            {/*                   cardImage={card3}*/}
            {/*                   buttonContent={"Explore"}/>*/}
            {/*    </div>*/}
            {/*</Container>*/}
            <Container className={'mt-5'}>
                <h5 style={{ fontSize: '1.5rem', fontWeight: 'bolder' }}>Service</h5>
                <Container className={'d-flex justify-content-left mt-3'}>
                    <div className={'d-flex justify-content-start me-5'}>
                        <Card onClick={handleOnOnclickAddVM} className={"m-2"} tyle={{padding: "1rem" ,width: '6rem', height: "6rem", display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                            <Card.Img style={{ height: '3rem', objectFit: 'scale-down' }} variant="top" src={add} />
                            <Card.Body style={{ padding: '0.5rem', textAlign: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                                <Card.Text style={{ fontSize: '0.6rem', fontWeight: 'lighter', margin: 0 }}>
                                    Thêm VM mới
                                </Card.Text>
                            </Card.Body>
                        </Card>
                        <Card onClick={handleOnOnclickVM} className={"m-2"} tyle={{padding: "1rem" ,width: '6rem', height: "6rem", display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                            <Card.Img style={{ height: '3rem', objectFit: 'scale-down' }} variant="top" src={vm} />
                            <Card.Body style={{ padding: '0.5rem', textAlign: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                                <Card.Text style={{ fontSize: '0.6rem', fontWeight: 'lighter', margin: 0 }}>Virtual Machines</Card.Text>
                            </Card.Body>
                        </Card>
                    </div>
                </Container>
            </Container>
            <ShowModalAuth errorCode={errorCode} errorStr={errorString} showModal={showModal} onHide={() => setShowModal(false)}/>
            <ShowModalCreateVm setShowModalCreateVm={setCreateVm} showModalCreateVm={createVm} onHide={() => setCreateVm(false)}/>
        </div>
    );
}
export default HomePage;