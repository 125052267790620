import React, {useEffect, useRef, useState} from 'react';
import Container from "react-bootstrap/Container";
import Card from "react-bootstrap/Card";
import {CardBody, Form} from "react-bootstrap";
import SHA512 from 'crypto-js/sha512';
import Cookies from 'js-cookie';
import {useNavigate} from "react-router-dom";
import Constants from "../home/Constants";


const SERVER = "http://localhost:8080";
const LOGIN_PATH = "/vm/microservices/v1/account-service/account/login";
export const Login= () => {
    const usernameRef = useRef("");
    const passwordRef = useRef("");
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [errorString, setErrorString] = useState("");
    const [showModal, setShowModal] = useState(false);
    const [errorCode, setErrorCode] = useState("");
    const [accessToken, setAccessToken] = useState("");
    const navigate = useNavigate();
    function toggleLogin () {
        if(usernameRef.current.value === "" || passwordRef.current.value === ""){
            setErrorCode("");
            setErrorString("Chưa điền đầy đủ thông tin!");
            setShowModal(true);
        }else{
            const loginOptions = {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({username: usernameRef.current.value, password: SHA512(usernameRef.current.value + passwordRef.current.value).toString()})
            };
            fetch(Constants.SERVER + LOGIN_PATH, loginOptions).then(response => {
                const statusCode = response.status;
                if (statusCode === 200) {
                    return response.json();
                } else {
                    response.text().then(body => {
                        let bodyFail = JSON.parse(body);
                        setErrorString(bodyFail['message']);
                        setShowModal(true);
                        setErrorCode(response.status);
                    });
                }
            }).then(data => {
                if(!Cookies.get('accessToken')){
                    Cookies.set('accessToken', data['accessToken']);
                }else{
                    Cookies.remove('accessToken');
                    Cookies.set('accessToken', data['accessToken']);
                }
                if(!Cookies.get('refreshToken')){
                    Cookies.set('refreshToken', data['refreshToken']);
                }else{
                    Cookies.remove('refreshToken');
                    Cookies.set('refreshToken', data['refreshToken']);
                }
                if(!Cookies.get('username')){
                    Cookies.set('username', usernameRef.current.value);
                }else{
                    Cookies.remove('username');
                    Cookies.set('username', usernameRef.current.value);
                }
                navigate('/manage');
            }).catch(error => {
                console.error('Có lỗi xảy ra:', error);
            });
        }
    }
    useEffect(() => {
        const accessToken = Cookies.get('accessToken');
        if (accessToken) {
            navigate('/manage');
        }
    }, []);
    return (
        <div>
            <Container className="py-5 h-100">
                <div className="row d-flex justify-content-center align-items-center h-100">
                    <div className="row d-flex justify-content-center align-items-center h-100">
                        <div className="col-12 col-md-8 col-lg-6 col-xl-5">
                            <Card className="text-white" style={{borderRadius: '1rem', backgroundColor: "#0078d4"}}>
                                <CardBody className="p-5">
                                    <div className="mb-md-5 mt-md-4 pb-5">
                                        <h2 className="text-center fw-bold mb-5 text-uppercase">Đăng nhập</h2>
                                        <Form>
                                            <Form.Group className="mb-3" controlId="formBasicUsername">
                                                <Form.Label className="text-start">Tên đăng nhập</Form.Label>
                                                <Form.Control ref={usernameRef} value={username} onChange={e => setUsername(e.target.value)} type="email" placeholder="Nhập tên đăng nhập" />
                                            </Form.Group>
                                            <Form.Group className="mb-3" controlId="formBasicPassword">
                                                <Form.Label>Mật khẩu</Form.Label>
                                                <Form.Control ref={passwordRef} value={password} onChange={e => setPassword( e.target.value)}
                                                              type="password" placeholder="Nhập mật khẩu" />
                                            </Form.Group>
                                        </Form>
                                        <p className="text-center small mb-5 pb-lg-2"><a className="text-white" href="#!">Quên mật khẩu?</a></p>
                                        <div className="text-center">
                                            <button onClick={toggleLogin} data-mdb-button-init="" data-mdb-ripple-init="" className="btn btn-outline-light btn-lg px-5" type="submit">Đăng nhập</button>
                                        </div>
                                    </div>
                                    <div className="text-center">
                                        <p className="mb-0">Bạn không có tài khoản? <a href="/register" className="text-white-50 fw-bold">Đăng ký</a>
                                        </p>
                                    </div>
                                </CardBody>
                            </Card>
                        </div>
                    </div>
                </div>
            </Container>
        </div>
    );
}
