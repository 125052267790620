import React from 'react';
import {Modal} from "react-bootstrap";
import {Button} from "react-bootstrap";
import {useNavigate} from "react-router-dom";

export const Popup = ({showPopup, hidePopup, title, content}) => {
    return (
        <Modal show={showPopup} backdrop="static" keyboard={false}>
            <Modal.Header closeButton>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{content}</Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={hidePopup}>Close</Button>
            </Modal.Footer>
        </Modal>
    );
}