import Container from "react-bootstrap/Container";
import Card from "react-bootstrap/Card";
import {CardBody, Form} from "react-bootstrap";
import React, {useRef, useState} from "react";
import {ShowModalAuth} from "../popup/fail401Login";
import {refreshToken} from "../util/refreshToken";
import {Popup} from "../popup/popup";
import Constants from "../home/Constants";
import SHA512 from "crypto-js/sha512";
import {useNavigate} from "react-router-dom";

export const Register = () => {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [email, setEmail] = useState("");
    const [fullName, setFullName] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [errorString, setErrorString] = useState("");
    const [showModal, setShowModal] = useState(false);
    const [errorCode, setErrorCode] = useState("");
    const [titlePopup, settTitlePopup] = useState("");
    const [titleContent, setTitleContent] = useState('');
    const [showPopup, setShowPopup] = useState(false);
    const navigate = useNavigate();
    function registerAccount () {
        const isValidUsername = (value) => /^[a-zA-Z0-9]+$/.test(value); // Chỉ cho phép chữ và số
        const isValidEmail = (value) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value); // Email chuẩn
        if(username.trim() === ""){
            setContentErrorPopup(403, "Username không được để trống", true);
        }else if(username.length < 6){
            setContentErrorPopup(403, "Username phải hơn 6 ký tự", true);
        }else if(password === ""){
            setContentErrorPopup(403, "password không được để trống", true);
        }else if(password.length < 8){
            setContentErrorPopup(403, "Username phải hơn 8 ký tự", true);
        }else if(email === ""){
            setContentErrorPopup(403, "email không được để trống", true);
        }else if(!isValidUsername(username)){
            setContentErrorPopup(403, "Username không chứa kí tự đặc biệt", true);
        }else if(!isValidEmail(email)){
            setContentErrorPopup(403, "Email không hợp lệ", true);
        }else{
            const registerOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    username: username,
                    password: SHA512(username + password).toString(),
                    email: email,
                    phoneNumber: phoneNumber,
                    fullName: fullName,
                })
            };
            fetch(Constants.SERVER + "/vm/microservices/v1/account-service/account/register", registerOptions).then(response => {
                const statusCode = response.status;
                if (statusCode === 200) {
                    return response.json();
                }else if(statusCode === 401){
                    refreshToken();
                    registerAccount();
                } else {
                    response.text().then(body => {
                        let bodyFail = JSON.parse(body);
                        setContentErrorPopup(response.status, bodyFail['message'], true);
                    });
                }
                return null;
            }).then(data => {
                if(data != null){
                    setShowPopup(true);
                    settTitlePopup("Thành công");
                    setTitleContent("Bạn đã đăng ký tài khoản thành công," +
                        " chúng tôi có gửi tới bạn thông tin kích hoạt tài khoản vào email bạn đã đăng ký!");
                    navigate('/');
                }
            }).catch(error => {
                console.error('Có lỗi xảy ra:', error);
            });
        }



    }

    function setContentErrorPopup(code, errorString, showModal){
        setErrorCode(code);
        setErrorString(errorString);
        setShowModal(showModal);
    }
    return (
        <div>
            <Container className="py-5 h-100">
                <div className="row d-flex justify-content-center align-items-center h-100">
                    <div className="row d-flex justify-content-center align-items-center h-100">
                        <div className="col-12 col-md-8 col-lg-6 col-xl-5">
                            <Card className="text-white" style={{borderRadius: '1rem', backgroundColor: "#0078d4"}}>
                                <CardBody className="p-5">
                                    <div className="mb-md-2 mt-md-2 pb-1">
                                        <h2 className="text-center fw-bold mb-2 text-uppercase">Đăng Ký</h2>
                                        <Form>
                                            <Form.Group className="mb-2" >
                                                <Form.Label className="text-start">Tên đăng nhập (<span style={{color: "red"}}>*</span>)</Form.Label>
                                                <Form.Control
                                                              value={username}
                                                              onChange={e => setUsername(e.target.value)}
                                                              type="text"
                                                              placeholder="Nhập tên đăng nhập" required={true}/>
                                            </Form.Group>
                                            <Form.Group className="mb-2" >
                                                <Form.Label>Mật khẩu (<span style={{color: "red"}}>*</span>)</Form.Label>
                                                <Form.Control
                                                              value={password}
                                                              onChange={e => setPassword( e.target.value)}
                                                              type="password"
                                                              placeholder="Nhập mật khẩu" required={true}/>
                                            </Form.Group>
                                            <Form.Group className="mb-2" >
                                                <Form.Label>Email (<span style={{color: "red"}}>*</span>)</Form.Label>
                                                <Form.Control
                                                              value={email}
                                                              onChange={e => setEmail( e.target.value)}
                                                              type="email"
                                                              placeholder="Nhập email" required={true}/>
                                            </Form.Group>
                                            <Form.Group className="mb-2" >
                                                <Form.Label>Tên đầy đủ</Form.Label>
                                                <Form.Control
                                                              value={fullName}
                                                              onChange={e => setFullName( e.target.value)}
                                                              type="text"
                                                              placeholder="Nhập tên đầy đủ" />
                                            </Form.Group>
                                            <Form.Group className="mb-2" >
                                                <Form.Label>Số điện thoại</Form.Label>
                                                <Form.Control
                                                              value={phoneNumber}
                                                              onChange={e => setPhoneNumber( e.target.value)}
                                                              type="text"
                                                              placeholder="Nhập số điện thoại" />
                                            </Form.Group>
                                        </Form>
                                        <div className="text-center">
                                            <button onClick={registerAccount}
                                                    className="btn btn-outline-light btn-lg px-5 mt-3"
                                                    type="submit">Đăng ký</button>
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </div>
                    </div>
                </div>
            </Container>
            <ShowModalAuth errorCode={errorCode} errorStr={errorString} showModal={showModal} onHide={() => setShowModal(false)}/>
            <Popup title={titlePopup} content={titleContent} showPopup={showPopup} hidePopup={() => setShowPopup(false)}/>
        </div>
    );
}