import {Button, Modal} from "react-bootstrap";
import React from "react";
import Cookies from "js-cookie";
import Constants from "../home/Constants";
const REFRESH_PATH = "/vm/microservices/v1/account-service/account/refresh-token";
export const refreshToken = (navigate) => {
    const refreshOptions = {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({username: Cookies.get('username'), refreshToken: Cookies.get("refreshToken")})
    };
    fetch(Constants.SERVER + REFRESH_PATH, refreshOptions).then(response => {
        const statusCode = response.status;
        if (statusCode === 200) {
            return response.json();
        } else {
            Cookies.remove('accessToken');
            Cookies.remove('refreshToken');
            Cookies.remove('username');
            navigate('/');
        }
    }).then(data => {
        if(!Cookies.get('accessToken')){
            Cookies.set('accessToken', data['accessToken']);
        }else{
            Cookies.remove('accessToken');
            Cookies.set('accessToken', data['accessToken']);
        }
    }).catch(error => {
        console.error('Có lỗi xảy ra:', error);
    });
}