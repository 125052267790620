import React from 'react';
import {Modal} from "react-bootstrap";
import {Button} from "react-bootstrap";

export const ShowModalAuth = ({showModal, onHide, errorCode, errorStr}) => {
    return (
        <Modal show={showModal} onHide={onHide} backdrop="static" keyboard={false}>
            <Modal.Header closeButton>
                <Modal.Title>Lỗi {errorCode}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{errorStr}</Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onHide}>Close</Button>
            </Modal.Footer>
        </Modal>
    );
}