import {useNavigate} from "react-router-dom";
import NavBarPage from "../navbar/NavBarPage";

import React, {useEffect, useState} from "react";
import Container from "react-bootstrap/Container";
import {Button, ButtonGroup, Col, Form, InputGroup, Pagination, Row} from "react-bootstrap";
import Cookies from "js-cookie";
import {refreshToken} from "../util/refreshToken";
import {ModifyAccount} from "../popup/ModifyAccount";
import {ChangePassword} from "../popup/ChangePassword";
import Constants from "../home/Constants";
const URL_TRANSACTION = Constants.SERVER + "/vm/microservices/v1/account-service/account/find-transaction";
const URL_DETAIL_ACCOUNT = Constants.SERVER + "/vm/microservices/v1/account-service/account/detail";
const User = () => {
    const navigate = useNavigate();
    const [token, setToken] = useState(Cookies.get('accessToken') || null);
    const [data, setData] = useState([]);
    const [modifyAcc, setModifyAccount] = useState(false);
    const [changePass, setChangePass] = useState(false);
    const [username, setUsername] = useState("");
    const [email, setEmail] = useState("");
    const [fullName, setFullName] = useState("");
    const [phoneNumber, setPhoneNumber] = useState('');
    const [balance, setBalance] = useState(0);
    const [itemsPerPage, setItemsPerPage] = useState(5);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const handlePageChange = (pageNumber) => {
        getData(pageNumber-1);
        setCurrentPage(pageNumber);
    }
    const getTransaction = () => {
        const loginOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + Cookies.get('accessToken')},
            body: JSON.stringify({
                limit: 5,
                page: 0,
            })
        };
        fetch(URL_TRANSACTION, loginOptions).then(response => {
            const statusCode = response.status;
            if (statusCode === 200) {
                return response.json();
            }else if(statusCode === 401){
                refreshToken();
            } else {
                response.text().then(body => {
                    JSON.parse(body);
                });
            }
        }).then(dataResult => {
        }).catch(error => {
            console.error('Có lỗi xảy ra:', error);
        });
    }

    function getBalance(){
        const balanceOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token},
        };
        fetch(Constants.SERVER + "/vm/microservices/v1/account-service/account/balance", balanceOptions).then(response => {
            const statusCode = response.status;
            if (statusCode === 200) {
                return response.json();
            }else if(statusCode === 401){
                refreshToken();
            } else {
                response.text().then(body => {
                    let bodyFail = JSON.parse(body);
                });
            }
        }).then(data => {
            setBalance(formatNumber(data.amount));
        }).catch(error => {
            console.error('Có lỗi xảy ra:', error);
        });
    }
    const formatNumber = (numberString) => {
        const number = parseInt(numberString, 10); // Chuyển chuỗi sang số
        return new Intl.NumberFormat('de-DE').format(number); // Định dạng theo chuẩn "1.000.000.000"
    };
    function getData(page){
        const loginOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token},
        };
        fetch(Constants.SERVER + "/vm/microservices/v1/account-service/account/find-transaction?limit="+itemsPerPage+"&page=" + page, loginOptions).then(response => {
            const statusCode = response.status;
            if (statusCode === 200) {
                return response.json();
            }else if(statusCode === 401){
                refreshToken();
            } else {
                response.text().then(body => {
                    let bodyFail = JSON.parse(body);
                });
            }
        }).then(data => {
            console.log(data);
            setData(data.data);
            setTotalPages(data.totalPages);
        }).catch(error => {
            console.error('Có lỗi xảy ra:', error);
        });
    }
    useEffect(() => {
        getData(0);
        getBalance();
    }, [itemsPerPage]);
    const detailAccount = () => {
        const detailOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + Cookies.get('accessToken')},
        };
        fetch(URL_DETAIL_ACCOUNT, detailOptions).then(response => {
            const statusCode = response.status;
            if (statusCode === 200) {
                return response.json();
            }else if(statusCode === 401){
                refreshToken();
            } else {
                response.text().then(body => {
                    JSON.parse(body);
                });
            }
        }).then(dataResult => {
            let dataJson = dataResult["account"];
            setUsername(dataJson["username"]);
            setEmail(dataJson["email"]);
            setFullName(dataJson["fullName"]);
            setPhoneNumber(dataJson["phoneNumber"]);
        }).catch(error => {
            console.error('Có lỗi xảy ra:', error);
        });
    }
    const handleItemsPerPageChange = (event) => {
        setItemsPerPage(Number(event.target.value));
        setCurrentPage(1);
    };
    const paginationItems = [];
    for (let i = 1; i <= totalPages; i++) {
        // console.log("i == " + i + " currentPage=" + currentPage);
        paginationItems.push(
            <Pagination.Item  key={i} active={i === currentPage} onClick={() => handlePageChange(i)}>
                {i}
            </Pagination.Item>
        );
    }
    const changePassword = (event) => {
        setChangePass(true);
    }
    const logout = (event) => {
        Cookies.remove("accessToken");
        Cookies.remove("refreshToken");
        navigate("/");
    }
    const modifyAccount = (event) => {
        setModifyAccount(true);
    }
    useEffect(() => {
        detailAccount();
    }, []);
    return (
        <div>
            <NavBarPage currentTab={4} navigate={navigate} />
            <Container className={"d-flex mt-5"}>
                <Container >
                    <div className={"d-flex justify-content-between"}>
                        <h4>Thông tin tài khoản</h4>
                        <div >
                            <ButtonGroup style={{marginRight: "1rem", color: "#0078d4"}}>
                                <Button onClick={changePassword} >Đổi mật khẩu</Button>
                            </ButtonGroup>
                            <ButtonGroup style={{marginRight: "1rem", color: "#0078d4"}}>
                                <Button onClick={logout}>Đăng xuất</Button>
                            </ButtonGroup>
                            <ButtonGroup style={{color: "#0078d4"}}>
                                <Button onClick={modifyAccount}>Chỉnh sửa</Button>
                            </ButtonGroup>
                        </div>
                    </div>
                    <div className={"border mt-5"} style={{borderRadius: "1rem", paddingRight: "5rem", paddingLeft: "5rem", paddingTop: "2rem", paddingBottom: "2rem"}}>
                        <div>
                            <span>Tên đăng nhập</span>
                            <InputGroup>
                                <Form.Control value={username} readOnly style={{borderRadius: "1rem"}}/>
                            </InputGroup>
                        </div>
                        <div className={"mt-3"}>
                            <span>Email</span>
                            <InputGroup>
                                <Form.Control readOnly value={email} style={{borderRadius: "1rem"}}/>
                            </InputGroup>
                        </div>
                        <div className={"mt-3"}>
                            <span>Họ và tên</span>
                            <InputGroup>
                                <Form.Control readOnly value={fullName} style={{borderRadius: "1rem"}}/>
                            </InputGroup>
                        </div>
                        <div className={"mt-3"}>
                            <span>Điện thoại</span>
                            <InputGroup>
                                <Form.Control readOnly value={phoneNumber} style={{borderRadius: "1rem"}}/>
                            </InputGroup>
                        </div>
                    </div>
                </Container>
                <Container className={"border rounded"} style={{marginLeft: "3rem", paddingTop: "1rem"}}>
                    <div className={"d-flex justify-content-center text-white rounded-top"}
                         style={{backgroundColor: "#0078d4"}}>
                        <h2>Lịch sử giao dịch</h2>
                    </div>
                    <div className={"d-flex justify-content-between rounded-top mt-3 mb-3"}>
                        <span>{"Số dư tài khoản " + balance + " VND"}</span>
                    </div>
                    <div>
                        <table className="table table-striped table-bordered">
                            <thead className="thead-dark">
                            <tr className={"text-center"}>
                                <th>Thời gian</th>
                                <th>Nội dung</th>
                            </tr>
                            </thead>
                            <tbody>
                            {data.map((item) => (
                                <tr key={item.id}>
                                    <td>{item.time}</td>
                                    <td>{item.detail}</td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </div>
                    <Row className="justify-content-between align-items-center">
                        <Col md="auto">
                            <Form.Select value={itemsPerPage} onChange={handleItemsPerPageChange} aria-label="Select items per page">
                                <option value={5}>5 bản ghi mỗi trang</option>
                                <option value={10}>10 bản ghi mỗi trang</option>
                                <option value={15}>15 bản ghi mỗi trang</option>
                                <option value={20}>20 bản ghi mỗi trang</option>
                            </Form.Select>
                        </Col>
                        <Col md="auto">
                            <Pagination>{paginationItems}</Pagination>
                        </Col>
                    </Row>
                </Container>
            </Container>
            <ModifyAccount setShowModal={setModifyAccount} showModal={modifyAcc} onHide={() => setModifyAccount(false)}/>
            <ChangePassword setShowModal={setChangePass} showModal={changePass} onHide={() => setChangePass(false)}/>
        </div>
    );
}
export default User;