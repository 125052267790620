import React, {useEffect, useRef, useState} from 'react';
import {Col, Form, Modal, Row} from "react-bootstrap";
import {Button} from "react-bootstrap";
import {refreshToken} from "../util/refreshToken";
import Cookies from "js-cookie";
import {ShowModalAuth} from "./fail401Login";
import {Popup} from "./popup";
import Constants from "../home/Constants";

export const ModifyAccount = ({showModal, onHide,  setShowModal}) => {
    const fullNameRef = useRef("");
    const phoneNumberRef = useRef("");
    const [fullName, setFullName] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [errorCode, setErrorCode] = useState(200);
    const [errorString, setErrorString] = useState(200);
    const [showModalFail, setModalFail] = useState(false);
    const [titlePopup, settTitlePopup] = useState("");
    const [titleContent, setTitleContent] = useState('');
    const [showPopup, setShowPopup] = useState(false);
    useEffect(() => {
        if (showModal) {
            const detailAccountOsOptions = {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + Cookies.get('accessToken')
                },
            };
            fetch(Constants.SERVER + "/vm/microservices/v1/account-service/account/detail", detailAccountOsOptions).then(response => {
                const statusCode = response.status;
                if (statusCode === 200) {
                    return response.json();
                } else if (statusCode === 401) {
                    refreshToken();
                } else {
                    response.text().then(body => {
                        JSON.parse(body);
                    });
                }
            }).then(dataResult => {
                if (dataResult) {
                    setFullName(dataResult.account.fullName || "");
                    setPhoneNumber(dataResult.account.phoneNumber || "");
                }
            }).catch(error => {
                console.error('Có lỗi xảy ra:', error);
            });
        }
    }, [showModal]);

    function handleModifyAccount() {
        const modifyAccount = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + Cookies.get('accessToken')
            },
            body: JSON.stringify({
                phoneNumber: phoneNumberRef.current.value,
                fullName: fullNameRef.current.value,
            }),
        };
        fetch(Constants.SERVER + "/vm/microservices/v1/account-service/account/update", modifyAccount).then(response => {
            const statusCode = response.status;
            if (statusCode === 200) {
                return response.json();
            } else if (statusCode === 401) {
                refreshToken();
            } else {
                response.text().then(body => {
                    let bodyFail = JSON.parse(body);
                    setErrorString(bodyFail['message']);
                    setModalFail(true);
                    setErrorCode(response.status);
                });
            }
        }).then(dataResult => {
            if (dataResult) {
                setShowPopup(true);
                setShowModal(false);
                settTitlePopup("Thành công");
                setTitleContent("Chỉnh sửa thông tin tài khoản thành công!");
            }
        }).catch(error => {
            console.error('Có lỗi xảy ra:', error);
        });
    }

    function onChangePhoneNumber(e) {
        setPhoneNumber(e.target.value);
    }
    function hidePopup(){
        setShowPopup(false);
    }
    return (
        <div>
            <Modal className={"modal-lg"} show={showModal} onHide={onHide} backdrop="static" keyboard={false}>
                <Modal.Header closeButton>
                    <Modal.Title>Chỉnh sửa tài khoản</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form style={{fontSize:"13px"}}>
                        <Form.Group as={Row} className="mb-3" controlId="formHorizontal">
                            <Form.Label column sm="2" style={{ fontSize: "13px" }}>Tên đầy đủ</Form.Label>
                            <Col sm="10">
                                <Form.Control onChange={(e) => setFullName(e.target.value)} value={fullName}  ref={fullNameRef} style={{ fontSize: "13px" }} placeholder="Nhập tên đầy đủ" />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3" controlId="formHorizontal">
                            <Form.Label column sm="2" style={{ fontSize: "13px" }}>Số điện thoại</Form.Label>
                            <Col sm="10">
                                <Form.Control onChange={onChangePhoneNumber} value={phoneNumber}  ref={phoneNumberRef} style={{ fontSize: "13px" }} placeholder="Số điện thoại" />
                            </Col>
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleModifyAccount}>Chỉnh sửa</Button>
                    <Button variant="secondary" onClick={onHide}>Close</Button>
                </Modal.Footer>
                <ShowModalAuth errorCode={errorCode} errorStr={errorString} showModal={showModalFail} onHide={() => setModalFail(false)}/>
            </Modal>
            <Popup title={titlePopup} content={titleContent} showPopup={showPopup} hidePopup={hidePopup}/>
        </div>

    );
}