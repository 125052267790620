import React, {useEffect, useRef, useState} from 'react';
import {Col, Form, InputGroup, Modal, Row} from "react-bootstrap";
import {Button} from "react-bootstrap";
import {faEye, faEyeSlash} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Popup} from "./popup";
import Cookies from "js-cookie";
import {refreshToken} from "../util/refreshToken";
import {ShowModalAuth} from "./fail401Login";
import {useNavigate} from "react-router-dom";
import Constants from "../home/Constants";

export const ChangePassword = ({showModal, onHide, setShowModal}) => {
    const oldPasswordRef = useRef("");
    const [oldPassword, setOldPassword] = useState("");
    const newPasswordRef = useRef("");
    const [newPassword, setNewPassword] = useState("");
    const confPasswordRef = useRef("");
    const [confPassword, setConfPassword] = useState("");
    const [showPassword1, setShowPassword1] = useState(false);
    const [showPassword2, setShowPassword2] = useState(false);
    const [showPassword3, setShowPassword3] = useState(false);
    const [titlePopup, settTitlePopup] = useState("");
    const [titleContent, setTitleContent] = useState('');
    const [showPopup, setShowPopup] = useState(false);
    const [errorCode, setErrorCode] = useState(200);
    const [errorString, setErrorString] = useState("");
    const [showModalFail, setModalFail] = useState(false);
    const navigate = useNavigate();
    const togglePasswordVisibility1 = () => {
        setShowPassword1(!showPassword1);
    };
    const togglePasswordVisibility2 = () => {
        setShowPassword2(!showPassword2);
    };
    const togglePasswordVisibility3 = () => {
        setShowPassword3(!showPassword3);
    };
    function handleChangePassword() {
        if(oldPasswordRef.current.value === "" || newPasswordRef.current.value === "" || confPasswordRef.current.value === ""){
            setErrorCode(403);
            setErrorString("Chưa điền đầy đủ thông tin!");
            setModalFail(true);
        }else if(newPasswordRef.current.value !== confPasswordRef.current.value){
            setErrorCode(403);
            setErrorString("Password chưa đúng!");
            setModalFail(true);
        }else{
            const changePassword = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + Cookies.get('accessToken')
                },
                body: JSON.stringify({
                    oldPassword: oldPasswordRef.current.value,
                    newPassword: newPasswordRef.current.value,
                }),
            };
            fetch(Constants.SERVER + "/vm/microservices/v1/account-service/account/change-password", changePassword).then(response => {
                const statusCode = response.status;
                if (statusCode === 200) {
                    return response.json();
                } else if (statusCode === 401) {
                    refreshToken();
                } else {
                    response.text().then(body => {
                        let bodyFail = JSON.parse(body);
                        setErrorString(bodyFail['message']);
                        setErrorCode(response.status);
                        setModalFail(true);
                    });
                }
            }).then(dataResult => {
                if (dataResult) {
                    setShowPopup(true);
                    setShowModal(false);
                    settTitlePopup("Thành công");
                    setTitleContent("Đổi password thành công!");
                    Cookies.remove('refreshToken');
                    Cookies.remove('accessToken');
                }
            }).catch(error => {
                console.error('Có lỗi xảy ra:', error);
            });
        }
    }
    function hidePopup(){
        setShowPopup(false);
        navigate("/");
    }
    return (
        <div>
            <Modal className={"modal-lg"} show={showModal} onHide={onHide} backdrop="static" keyboard={false}>
                <Modal.Header closeButton>
                    <Modal.Title>Chỉnh sửa tài khoản</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form style={{fontSize:"13px"}}>
                        <Form.Group as={Row} className="mb-3">
                            <Form.Label column sm="2" style={{ fontSize: "13px" }}>Mật khẩu cũ</Form.Label>
                            <Col sm="10">
                                <InputGroup>
                                    <Form.Control type={showPassword1 ? "text" : "password"}
                                                  onChange={(e) => setOldPassword(e.target.value)}
                                                  value={oldPassword}
                                                  ref={oldPasswordRef} style={{ fontSize: "13px" }}
                                                  placeholder="Mật khẩu cũ" required={true}/>
                                    <button type="button"
                                            onClick={togglePasswordVisibility1}>{showPassword1 ? <FontAwesomeIcon style={{padding: "0.5rem"}} icon={faEye}/> : <FontAwesomeIcon style={{padding: "0.5rem"}} icon={faEyeSlash}/>} {/* Toggle icon */}
                                    </button>
                                </InputGroup>
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3" >
                                <Form.Label column sm="2" style={{ fontSize: "13px" }}>Mật khẩu mới</Form.Label>
                                <Col sm="10">
                                    <InputGroup>
                                        <Form.Control type={showPassword2 ? "text" : "password"}
                                                      onChange={(e) => setNewPassword(e.target.value)}
                                                      value={newPassword}
                                                      ref={newPasswordRef}
                                                      style={{ fontSize: "13px" }}
                                                      placeholder="Mật khẩu mới" />
                                        <button type="button"
                                                className={"btn-secondary"}
                                                onClick={togglePasswordVisibility2}>{showPassword2 ? <FontAwesomeIcon style={{padding: "0.5rem"}} icon={faEye}/> : <FontAwesomeIcon style={{padding: "0.5rem"}} icon={faEyeSlash}/>} {/* Toggle icon */}
                                        </button>
                                    </InputGroup>
                                </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3">
                            <Form.Label column sm="2" style={{ fontSize: "13px" }}>Xác nhận mật khẩu mới</Form.Label>
                            <Col sm="10">
                                <InputGroup>
                                    <Form.Control type={showPassword3 ? "text" : "password"}
                                                  onChange={(e) => setConfPassword(e.target.value)}
                                                  value={confPassword}
                                                  ref={confPasswordRef}
                                                  style={{ fontSize: "13px" }}
                                                  placeholder="Xác nhận" />
                                    <button type="button"
                                            className={"btn-secondary"}
                                            onClick={togglePasswordVisibility3}>{showPassword3 ? <FontAwesomeIcon style={{padding: "0.5rem"}} icon={faEye}/> : <FontAwesomeIcon style={{padding: "0.5rem"}} icon={faEyeSlash}/>} {/* Toggle icon */}
                                    </button>
                                </InputGroup>
                            </Col>
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleChangePassword}>Đổi mật khẩu</Button>
                    <Button variant="secondary" onClick={onHide}>Close</Button>
                </Modal.Footer>
            </Modal>
            <ShowModalAuth errorCode={errorCode} errorStr={errorString} showModal={showModalFail} onHide={() => setModalFail(false)}/>
            <Popup title={titlePopup} content={titleContent} showPopup={showPopup} hidePopup={hidePopup}/>
        </div>

    );
}